<template>
    <div>
        <div class="bg-green rounded d-flex flex-column align-items-center justify-content-center p-5">
            <b-icon class="text-purple bg-white rounded-circle p-3 d-flex align-items-center" font-scale="5" icon="list-ul"></b-icon>
            <div class="text-white fw-bold fs-5 my-2">Nova Tabulação</div>
            <b-button class="text-green bg-white border-0" v-b-modal.saveTab>Criar</b-button>
        </div>
        <div class="mt-3 bg-white rounded">
            <div class="d-flex align-items-center justify-content-between text-purple p-4 border-bottom">
                <div class="d-flex align-items-center fs-5">
                    Tabulações
                </div>
                <div class="d-flex align-items-center">
                    <div>Total de Tabulações</div>
                    <div class="rounded-pill py-0 px-2 text-white bg-purple mx-3 fs-4">
                        {{ tabulations.length || 0 }}
                    </div>
                    <div>
                        <b-form-input class="text-purple" placeholder="Busca por Nome Tabulação" @input="searchTab"></b-form-input>
                    </div>
                </div>
            </div>
            <div class="text-purple"  v-if="getTabs().length">
                <b-row class="m-0 px-4 py-3">
                    <b-col class="p-0" cols="4">
                        <div class="fw-bold">Texto</div>
                    </b-col>
                    <b-col class="p-0">
                        <div class="fw-bold">Visível em</div>
                    </b-col>
                </b-row>
                <div>
                    <b-row class="m-0 px-4 py-2 border-top" v-for="item in getTabs()" :key="item.id">
                        <b-col class="p-0 d-flex align-items-center" cols="4">
                            <!-- <b-form-checkbox>
                            </b-form-checkbox> -->
                            <div class="text-truncate">
                                {{ item.text }}
                            </div>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center justify-content-between">
                            <div>
                                <div class="text-purple" v-if="item.departments?.length">
                                    <span v-if="item.departments?.length && item.departments.at(0) == 'all'">
                                        Todos
                                    </span>
                                    <span v-else>
                                        <span v-for="(dep,i) in item.departments" :key="dep?._id">
                                            <span>
                                                {{ depName(dep) }}<span v-if="i+1<item.departments.length">,</span> 
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <b-button class="manage-btn" variant="green" v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Editar" @click="selectItem(item)" v-b-modal.saveTab>
                                    <b-icon class="text-white" icon="pencil-square"></b-icon>
                                </b-button>
                                <b-button class="manage-btn" variant="red" v-b-tooltip.hover.bottom="{ variant: 'red', customClass: 'top-0', boundary: 'document' }" title="Remover" @click="selectItem(item)" v-b-modal.deleteTab>
                                    <b-icon class="text-white" icon="trash"></b-icon>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="text-purple p-3 text-center" v-else>
                Nenhuma Tabulação encontrada!
            </div>
        </div>
        <b-modal id="saveTab" ref="saveTab" title="Editar Tabulação" header-class="py-1" hide-footer>
            <div class="vstack gap-3">
                <b-form-group label="Texto:" label-class="text-secondary">
                    <b-form-input class="border-grey" v-model="objAux.text" type="text"></b-form-input>
                </b-form-group>
                <b-form-group label="Visível em:" label-class="text-secondary">
                    <div class="position-relative">
                        <div class="border rounded w-100 text-secondary py-1-5 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseDepSelect>
                            <span v-if="objAux.departments?.length">
                                <span v-if="objAux.departments.at(0) == 'all'">
                                    Todos
                                </span>
                                <span v-else>
                                    <span v-for="(dep,i) in objAux.departments" :key="dep.id">
                                        <span>
                                            {{ depName(dep) }}
                                            <span v-if="i+1 < objAux.departments.length">,</span> 
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span v-else>
                                &nbsp;
                            </span>
                            <span>
                                <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                            </span>
                        </div>
                        <b-collapse id="collapseDepSelect" ref="collapseDepSelect" class=" position-absolute bg-white border w-100 rounded" style="z-index: 1">
                            <b-form-checkbox-group
                                v-model="objAux.departments"
                                @change="verifyDepartments"
                                style="z-index: 1"
                            >
                                <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" value="all" @change="verifyAllChecked"> 
                                    <div class="ms-1" role="button">Todos</div>
                                </b-form-checkbox>
                                <div v-for="item in departments" :key="item.id">
                                    <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item._id"> 
                                        <div class="ms-1" role="button">{{ item.name }}</div>
                                    </b-form-checkbox>
                                </div>
                            </b-form-checkbox-group>
                            <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseDepSelect>
                            </div>
                        </b-collapse>
                    </div>
                </b-form-group>
                <div class="d-flex">
                    <b-button class="modal-btn modal-btnsearch" @click="saveTab()">Salvar</b-button>
                    <b-button class="modal-btn modal-btncancel" variant="danger" @click="$refs['saveTab'].hide()">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="deleteTab" ref="deleteTab" title="Remover Tabulação" header-class="py-1" hide-footer body-class="p-0" v-if="selectedItem">
            <div class="border-bottom text-secondary smaller-text p-3">
                Tem certeza de que deseja remover esta tabulação?
            </div>
            <div class="d-flex p-3">
                <b-button class="modal-btn modal-btnsearch" @click="deleteTab()">Remover</b-button>
                <b-button class="modal-btn modal-btncancel" variant="danger" @click="$refs['deleteTab'].hide()">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import structuredClone from '@ungap/structured-clone';

export default {
    props: ['user'],
    mounted: function() {
        this.$nextTick(function() {
            this.onCloseModal()
            this.getTabulations()
            this.getDepartments()
        })
    },
    data() {
        return{
            tabulations: [],
            departments: [],
            selectedItem: null,
            objAux: {},
            search: null,
        }
    },
    methods: {
        selectItem(item) {
            if(!item.departments)
                item.departments = []
            else if(this.departments?.length && item.departments.length && item.departments.at(0) == 'all') {
                for (let dep of this.departments) {
                    item.departments.push(dep._id)
                }
            }

            this.selectedItem = item
            this.objAux = structuredClone(item);
        },
        async getTabulations() {
            let resp = await api.getTabulations(this.user.channelId || this.user.roleId)
            console.log('resp',resp)
            if(resp.statusCode == 200)
                this.tabulations = resp.tabulations
            else {
                // let msg = {
                //     text: 'Ocorreu um erro!',
                //     success: false
                // }
                // this.$emit('msg',msg)
            }
        },
        async getDepartments() {
            let resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode!=200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
            // console.log('departments',this.departments)
        },
        async saveTab() {
            if (!this.objAux.text) {
                return this.$emit('msg', { text: 'Campo "Texto" vazio!', success: false });
            }

            if(this.objAux.departments?.includes('all') && this.objAux.departments.length > 1) {
                this.objAux.departments = ['all' ]
            }

            const isNew = !this.objAux._id;
            const resp = isNew
                ? await api.createTabulation({ reason: [this.objAux] }, this.user.channelId || this.user.roleId)
                : await api.updateTabulation(this.objAux, this.user.channelId || this.user.roleId);

            const logType = isNew ? 'save' : 'update';
            console.log(`resp ${logType}`, resp);

            const successCodes = [200, 201];
            if (successCodes.includes(resp.statusCode)) {
                const successText = `Tabulação ${isNew ? 'criada' : 'editada'} com sucesso!`;
                this.getTabulations();
                this.$refs['saveTab'].hide();
                this.$emit('msg', { text: successText, success: true });
            } else {
                this.$emit('msg', { text: 'Ocorreu um erro!', success: false });
            }
        },
        depName(_id) {
            const dep = this.departments.find(el => el._id === _id);
            return dep ? dep.name : undefined;
        },
        async deleteTab() {
            let resp = await api.deleteTabulation({ reason:this.objAux }, this.user.channelId || this.user.roleId)
            console.log('resp delete',resp)
            let msg = null
            if(resp.statusCode == 200) {
                msg = {
                    text: `Tabulação removida com sucesso!`,
                    success: true
                }
                this.getTabulations()
                this.$refs['deleteTab'].hide()
            } else {
                msg = {
                    text: `Ocorreu um erro!`,
                    success: false
                }
            }
            this.$emit('msg',msg)
        },
        getTabs() {
            if(this.search) {
                return this.tabulations.filter(el => el.text.toLowerCase().includes(this.search.toLowerCase()))
            }
            return this.tabulations
        },
        searchTab(search) {
            this.search = search
        },
        verifyAllChecked(e) {
            if(!e.includes("all")) {
                this.objAux.departments = []
            }
        },
        verifyDepartments(e) {
            if(e.includes("all")) {
                if(e.at(-1) != "all") {
                    this.objAux.departments = this.objAux.departments.filter(e => e != "all")
                } else {
                    this.objAux.departments = ["all"]
                    this.departments.forEach(element => {
                        this.objAux.departments.push(element._id)
                    })
                }
            }
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.selectedItem = null
                this.objAux = {}
            })
        },
    }
}
</script>